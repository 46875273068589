<template>
  <header :class="headerClass" :data-display-mobile-menu="showStatues.showHomeMenu || showStatues.showProfileMenu">
    <RouterLink active-class="active" to="/" :title="t.aam" class="aamLogo en" data-test-id="logo"><AAMLogo /></RouterLink>
    <RouterLink active-class="active" to="/" :title="t.aam" class="aamLogoMobile en" data-test-id="logo"><AAMLogoMobile /></RouterLink>
    <RouterLink active-class="active" to="/" :title="t.aam" class="aamLogo ar" data-test-id="logo"><AAMLogoFlipped /></RouterLink>
    <RouterLink active-class="active" to="/" :title="t.aam" class="aamLogoMobile ar" data-test-id="logo"><AAMLogoMobileFlipped /></RouterLink>
    <div class="nav-container">
      <nav>
        <RouterLink active-class="active" to="/search">{{ t.search_cars }}</RouterLink>
        <a href="https://www.aamotors.com/our_businesses/cpov" target="_blank">{{ t.about }}</a>
        <a href="/#frequently-asked-questions">{{ t.faq }}</a>
        <RouterLink active-class="active" to="/contact">{{ t.contact }}</RouterLink>
        <div class="divition" />
      </nav>
      <LanguageDropdown class="lang-btn" />
      <button class="mobile-burger-btn" @click="toggleMobileMenu">
        <IconClose class="close" v-if="showStatues.showHomeMenu || showStatues.showProfileMenu" />
        <IconBurger v-else />
      </button>
      <button ref="userMenu" class="user-dropdown-btn" @click="toggleUserDropdown">
        <IconProfile />
        <ul v-if="showUserDropdown" class="user-dropdown-list">
          <li v-if="!currentUser" key="login" @click="login">
            <div>
              <span><IconLogout /></span>
              <span>{{ languageResources?.Profile?.login }}</span>
            </div>
          </li>
          <template :key="route.name" v-for="route in userRoutes">
            <li v-if="!route.hidden">
              <RouterLink :to="route.path">
                <component :is="route.icon" />
                {{ languageResources?.Profile?.[route.name] }}</RouterLink
              >
            </li>
          </template>
          <li v-if="currentUser" key="logout" @click="logout">
            <div>
              <span><IconLogout /></span>
              <span>{{ languageResources?.Profile?.logout }}</span>
            </div>
          </li>
        </ul>
      </button>
    </div>
    <NavMobileMenu :statuses="showStatues" @change="changeStatuses" />
  </header>
</template>

<script>
import { RouterLink } from 'vue-router'
import AAMLogo from '@/assets/aam-logo.svg'
import AAMLogoFlipped from '@/assets/aam-logo-flipped.svg'
import CPOVLogo from '@/assets/cpov-logo.svg'
import AAMLogoMobile from '@/assets/mobile-logo.svg'
import AAMLogoMobileFlipped from '@/assets/mobile-logo-flipped.svg'
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import IconProfile from '@/assets/icons/profile.svg'
import IconUser from '@/assets/icons/user.svg'
import IconHeart from '@/assets/icons/heart.svg'
import IconFolder from '@/assets/icons/folder.svg'
import IconLogout from '@/assets/icons/logout.svg'
import IconQatar from '@/assets/icons/qatar.svg'
import IconBurger from '@/assets/icons/burger.svg'
import IconClose from '@/assets/icons/solid-close.svg'
import { langMixin } from '@/lang.js'
import NavMobileMenu from './NavMobileMenu.vue'
import LanguageDropdown from './LanguageDropdown.vue'

export default {
  name: 'RootApp',
  components: { RouterLink, AAMLogo, IconChevronDown, IconProfile, IconLogout, IconQatar, AAMLogoMobile, IconBurger, IconClose, NavMobileMenu, LanguageDropdown, CPOVLogo, AAMLogoFlipped, AAMLogoMobileFlipped },
  mixins: [langMixin('Common')],
  inject: ['currentUser'],
  props: {
    headerClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showUserDropdown: false,
      showStatues: {
        showHomeMenu: false,
        showProfileMenu: false
      }
    }
  },
  computed: {
    userRoutes() {
      return [
        {
          icon: IconUser,
          name: 'my_account',
          path: '/me/account',
          hidden: !this.currentUser
        },
        {
          icon: IconHeart,
          name: 'favorites',
          path: '/me/favorites'
        },
        {
          icon: IconFolder,
          name: 'saved_searches',
          path: '/me/saved-searches'
        }
      ]
    },
    languages() {
      return [
        {
          icon: IconQatar,
          name: 'ar'
        },
        {
          icon: IconQatar,
          name: 'en'
        }
      ]
    },
    currentLangIcon() {
      return this.languages.find(item => item.name === this.language)?.icon
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideUserDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutsideUserDropdown)
  },
  methods: {
    clickOutsideUserDropdown(event) {
      if (!this.$refs.userMenu.contains(event.target)) {
        this.showUserDropdown = false
      }
    },
    toggleUserDropdown() {
      this.showUserDropdown = !this.showUserDropdown
    },
    login() {
      return this.$root.login()
    },
    logout() {
      window.seezSdk.showLogout()
    },
    toggleMobileMenu() {
      if (this.showStatues.showHomeMenu || this.showStatues.showProfileMenu) {
        this.showStatues.showHomeMenu = false
        this.showStatues.showProfileMenu = false
      } else {
        this.showStatues.showHomeMenu = true
      }
    },
    changeStatuses(newStatues) {
      this.showStatues = newStatues
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base';
@import '@/assets/styles/mixins';

header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem var(--content-side-padding);
  background-color: var(--background);
  box-shadow: var(--shadow);
  place-items: center;
  position: sticky;
  top: 0;
  z-index: 10;

  &.hidden {
    opacity: 0;
    pointer-events: none;
    z-index: -1;
  }

  .nav-container {
    display: flex;
    gap: 2rem;

    @media screen and (max-width: 36rem) {
      gap: 0.875rem;
    }
  }

  .aamLogo {
    > svg {
      * {
        fill: var(--accent);
      }
    }
    @media screen and (max-width: 48rem) {
      display: none;
      height: 0;
    }
  }
  .aamLogoMobile {
    > svg {
      * {
        fill: var(--accent);
      }
    }
    display: none;
    @media screen and (max-width: 48rem) {
      display: block;
    }
  }
  .en {
    &:lang(ar) {
      display: none;
    }
  }
  .ar {
    &:lang(en) {
      display: none;
    }
  }

  nav {
    display: none;
    gap: 2.125rem;

    @include minScreen(lg) {
      display: flex;
      align-items: center;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.5rem 0;
      border-radius: 0.5rem;
      position: relative;

      &.active::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 50%;
        height: 2px;
        background-color: var(--primary);
      }
    }
  }

  .divition {
    width: 1px;
    height: 30px;
    background-color: #e6e6e6;
  }

  @media screen and (max-width: 75rem) {
    .lang-btn {
      display: none;
    }
  }

  .user-dropdown-btn {
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    display: flex;
  }

  .language-btn {
    align-items: center;
    padding: 0;
    gap: 0.5rem;
  }

  .user-dropdown-btn {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    border: 2px solid var(--primary);
    align-items: end;
    justify-content: center;

    > svg {
      position: absolute;
      bottom: -2px;
      z-index: -1;
    }
  }

  .user-dropdown-list {
    z-index: 3;
    position: absolute;
    right: 0;
    top: 4rem;
    margin: 0;
    padding: 0.5rem 0.8rem;
    list-style-type: none;
    border-radius: 0.25em;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    html[dir='rtl'] & {
      left: 0;
      right: unset;
    }
  }

  .user-dropdown-list {
    background-color: var(--dark-background);

    li {
      border-radius: 0.25rem;

      > a,
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem;
        color: var(--background);
      }

      &:hover {
        background-color: var(--background);

        a,
        span,
        svg {
          color: var(--font-color);
        }
      }
    }
  }

  .mobile-burger-btn {
    display: block;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg * {
      stroke: var(--accent);
    }

    svg.close * {
      fill: var(--accent);
    }

    @media screen and (min-width: 75rem) {
      display: none;
    }
  }
}
</style>
